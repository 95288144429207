<template>
  <div class="login-container">
    <!-- 背景图 -->
    <!-- <div class="hande_login_background"> -->
    <!-- 登录背景图 -->
    <div class="hande_login_bgc">
      <!-- 登录输入框 -->
      <div class="hande_login_box">
        <div class="hande_login_text">
          <p class="">登录 /</p>
          <p>翰德代发系统</p>
        </div>
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          class="login-form"
        >
          <el-form-item prop="username">
            <el-input
              v-model="loginForm.username"
              type="text"
              auto-complete="off"
              prefix-icon="el-icon-user"
              placeholder="请输入您的用户名"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              type="password"
              auto-complete="off"
              placeholder="请输入您的密码"
              prefix-icon="el-icon-lock"
              
              
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              :loading="loading"
              size="medium"
              type="primary"
              style="width: 100%"
              @click.native.prevent="handleLogin"
              @keyup.enter="handleLogin"
            >
              <span v-if="!loading" >登 录</span>
              <span v-else>登 录 中...</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="el-login-footer">
      <span>Copyright © 2023-628翰德代发系统.</span>
    </div>
  </div>
</template>

<script>
import { posswordLongin } from '@/api/login.js'
import { setToken } from '@/utils/storage.js'
export default {
  name: 'login',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          { required: true,message: '请输入您的手机号', trigger: ['blur', 'change'] },
          {
            max: 12,
            message: '手机号长度最多不能超过11位',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[0-8]|9[0-9])\d{1}))\d{7}$/,
            message: '请输入正确的手机号',
            trigger: ['blur', 'change']
          }
          ],
        password: [
          { required: true, message: '请输入您的密码', trigger: ['blur', 'change']},
          { min: 1,max: 16,message: '密码长度不超过16位',trigger: ['blur', 'change']}
          ]
      },
      loading: false,
      showDialog: false
    }
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          this.loading = true
          setInterval(() => {
            this.loading = false
          }, 10000);
          const res = await posswordLongin ({
            username:this.loginForm.username,
            password:this.loginForm.password,
          })
          
           if ( res.success ) {
              this.loading = false
              setToken(res.data.token)
              this.$message.success('登录成功')
              this.$router.push({ path: '/' })
           } else {
              // this.$message.error(res.message)
             this.loading = false
           }
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    keyDown (e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
          this.handleLogin() // 定义的登录方法
      }
    },
  },
  mounted () {
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed() {
     window.removeEventListener('keydown', this.keyDown, false)
  }
}
</script>

<style lang="less" >
.login-container {
  height: 100vh;
  // height: 100%;
  background-image: url("../../assets/bgm/bande_banger.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .hande_login_bgc {
    width: 1758px;
    height: 872px;
    background-image: url("../../assets/bgm/hande_login.png");
    background-size: cover;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    .hande_login_box {
      width: 360px;
      height: 336px;
      margin-right: 240px;
      .hande_login_text {
        display: flex;
        p:first-child {
          width: 68px;
          height: 33px;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 33px;
        }
        p:last-child {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-top: 10px;
          margin-left: 5px;
        }
       }
      .el-form {
        margin-top: 20px;
      }
    }
  }
  .el-login-footer {
    height: 30px;
    line-height: 30px;
    position: fixed;
    bottom: 20px;
    width: 100%;
    text-align: center;
    color: #000;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }

}
</style>
